import { setLoginUser, setLoginFlag } from "../login/loginAction";
import { setloader } from "../loader/loaderAction";
// import {setDashboardCaradData} from '../dashboard/dashboardAction';
import {
  setALlCategory,
  setCategoryData,
  setDocumentData,
} from "../category/categoryAction";
import {
  setUserData,
  setCurrentPortfolioData,
  setCurrentCertificateData,
} from "../user/userAction";
import {
  setCurrentUserData,
  setSkillsUserData,
  setMutiSkillsUserData,
  setTransportUserData,
  setMutiTransportUserData,
} from "../user/userAction";
import { api } from "../../../api/api";
import { history } from "../../../app/AppRouter";
import { toastr } from "react-redux-toastr";
import { setForgetPassword } from "../forgetPassword/forgetPasswordAction";
import { setNewPassword } from "../setPassword/setPasswordAction";
import { setResetPassword } from "../reset-password/resetpassword";
import {
  setSkillsData,
  setLangulageData,
  setTransportsData,
} from "../skills/skills";
import {
  setCurrentTaskDetails,
  setTaskCommnet,
  setCurrentOfferData,
} from "../task/taskAction";
import { setMarkerImages } from "../markerImage/markerImage";
import { setAllSettings } from "../Settings/settingsActions";
import { setAllTransactionData } from "../transaction/transactionAction";
import {
  getAllUnAssignedVouchers,
  setAllPartnerVouchers,
  setCurrentVoucherData,
} from "../vouchers/voucherActions";
import { setCurrentTrackingData } from "../tracking/trackingActions";
import { getUserPermissions } from "./userPermissions/userPermissionsActions";
import {
  setSubPermission,
  setUserPermissions,
} from "../userPermissions/userPermissions";

export const loginApi = (value) => {
  return async (dispatch, store) => {
    await api("login", value, "postWithoutToken")
      .then((res) => {
        if (res.status === 400) {
          toastr.error(res.data.error || res.data.message);
        } else {
          const firstPermissionPage =
            res.data.data &&
            res.data.data.role &&
            res.data.data.role.isSuperAdmin
              ? { path: "/user" }
              : res.data.data.role.permissions &&
                res.data.data.role.permissions.find(
                  (item) => item.isAccess === true && !item.path.includes(":id")
                );
          const reDirectingPath =
            firstPermissionPage && firstPermissionPage.path
              ? firstPermissionPage.path
              : "/user";
          dispatch(setLoginUser(res.data.data));
          dispatch(getUserPermissions(res.data.data.id));
          localStorage.setItem("cotasker", JSON.stringify(res.data.data));
          dispatch(setLoginFlag(true));
          dispatch(setLoginFlag(true));
          toastr.success("Logged in Successfully");
          history.push(reDirectingPath);
        }
      })
      .catch((err) => {
        toastr.error("You are not able to login!");
      });
  };
};

export const forgotPasswordApi = (value) => {
  return async (dispatch, store) => {
    await api("generatePasswordUser", value, "postWithoutToken")
      .then((res) => {
        if (res.status === 400) {
          toastr.error(res.data.error);
        } else {
          dispatch(setForgetPassword(value));
          toastr.success("OTP has been succefully sent to your email!");
          history.push("/set-password");
        }
      })
      .catch((err) => {
        toastr.error("You are not able to login!");
      });
  };
};

export const setPasswordApi = (value) => {
  return async (dispatch, store) => {
    await api("setPassword", value, "postWithoutToken")
      .then((res) => {
        if (res.status === 400) {
          toastr.error(res.data.error);
        } else {
          dispatch(setNewPassword(res.data.data));
          toastr.success("You have changed your password successfully.!");
          history.push("/login");
        }
      })
      .catch((err) => {
        toastr.error("You are not able to login!");
      });
  };
};

export const setResetPasswordApi = (value) => {
  return async (dispatch, store) => {
    await api("resetPassword", value, "post")
      .then((res) => {
        if (res.status === 400) {
          toastr.error(res.data.error);
        } else {
          dispatch(setResetPassword(res.data.data));

          toastr.success("Password reset successfully!");
          history.push("/user");
        }
      })
      .catch((err) => {
        toastr.error("You are not able to login!");
      });
  };
};

export const reUploadDoc = (value) => {
  return async (dispatch, store) => {
    //console.log({value})

    await api("admin/reSubmitUserDoc", value, "post")
      .then((res) => {
        if (res.status === 400) {
          toastr.error(res.data.error);
        } else {
          toastr.success("Document Uploaded Succesfully");
        }
      })
      .catch((err) => {
        toastr.error("You are not able to login!");
      });
  };
};

export const reUploadDocByAdmin = (value) => {
  return async (dispatch, store) => {
    //console.log({value})

    await api("admin/reUploadDocByAdmin", value, "post")
      .then((res) => {
        if (res.status === 400) {
          toastr.error(res.data.error);
        } else {
          toastr.success("Document Re-Uploaded Succesfully");
        }
      })
      .catch((err) => {
        toastr.error("You are not able to login!");
      });
  };
};

// export const getDashboardCardData = (value) => {
//     return async (dispatch, store) => {
//
//         await api('admin/dashboard',value,'get').then((res)=>{
//             if(res.status == 400){
//                 toastr.error(res.data.error)
//             }else{
//              dispatch(setDashboardCaradData(res.data.data));
//             }
//
//         }).catch((err)=>{
//             toastr.error('You are not able to login!')
//         })
//     }
// }

export const downloadInvoice = (value) => {
  return async (dispatch, store) => {
    await api(`admin/downloadInvoices?url=${value}`, value, "get")
      .then((res) => {
        if (res.status == 400) {
          toastr.error(res.data.error);
        } else {
          // dispatch(setDashboardCaradData(res.data.data));
        }
      })
      .catch((err) => {
        toastr.error("You are not able to login!");
      });
  };
};

// if all = "ALL" then get all PC and C type categories in category.allCategories redux state
export const getAllCategoryData = (value, all = "") => {
  return async (dispatch, store) => {
    let filter = "";

    if (value && value.withMapPinUrl) {
      filter = "&withMapPinUrl=" + true;
    }
    const url = all !== "" ? "lookup/getV3?type=C" : "lookup/get?type=C";
    await api(url + filter, "", "get")
      .then((res) => {
        if (res.status === 400) {
          toastr.error(res.data.error);
        } else {
          if (all === "ALL") {
            dispatch(setALlCategory(res.data.data));
          } else {
            dispatch(setCategoryData(res.data.data));
          }
        }
      })
      .catch((err) => {
        toastr.error("You are not able to login!");
      });
  };
};

export const getAllDocumentData = () => {
  //console.log('Inside getAllDocumentData start')
  return async (dispatch, store) => {
    await api("lookup/get?type=DO", "", "get")
      .then((res) => {
        if (res.status === 400) {
          toastr.error(res.data.error);
        } else {
          dispatch(setDocumentData(res.data.data));
        }
      })
      .catch((err) => {
        toastr.error("You are not able to login!");
      });
    //console.log('Inside getAllDocumentData end')
  };
};

export const getLookupData = (value) => {
  return async (dispatch, store) => {
    await api(`lookup/getmulti`, value, "post")
      .then((res) => {
        if (res.status === 400) {
          toastr.error(res.data.error);
        } else {
          let skillsData = [];
          let languageData = [];
          let documentData = [];
          let transportData = [];

          res.data.data.forEach((item) => {
            if (item.type == "S") {
              skillsData.push(item);
            }
            if (item.type == "L") {
              languageData.push(item);
            }
            if (item.type == "DO") {
              documentData.push(item);
            }
            if (item.type == "T") {
              transportData.push(item);
            }
          });

          if (value.lookupArray.includes("S")) {
            for (var i = 0; i < skillsData.length; i++) {
              // res.data.data[i]['value'] = res.data.data[i].id;
              skillsData[i]["label"] = skillsData[i].value;
            }

            dispatch(setSkillsData(skillsData));
          }

          if (value.lookupArray.includes("DO")) {
            dispatch(setDocumentData(documentData));
          }

          if (value.lookupArray.includes("L")) {
            for (let i = 0; i < languageData.length; i++) {
              // res.data.data[i]['value'] = res.data.data[i].id;
              languageData[i]["label"] = languageData[i].value;
            }

            dispatch(setLangulageData(languageData));
          }

          if (value.lookupArray.includes("T")) {
            for (let i = 0; i < transportData.length; i++) {
              // res.data.data[i]['value'] = res.data.data[i].id;
              transportData[i]["label"] = transportData[i].value;
            }

            dispatch(setTransportsData(transportData));
          }
        }
      })
      .catch((err) => {
        toastr.error("You are not able to login");
      });
  };
};

export const getAllUserData = (value) => {
  return async (dispatch, store) => {
    //
    await api(`listUser?status=${value}`, "", "get")
      .then(async (res) => {
        if (res.status === 400) {
          toastr.error(res.data.error);
        } else {
          await dispatch(setUserData(res.data.data));
        }
        // await
      })
      .catch((err) => {
        toastr.error("You are not able to login");
      });
  };
};

export const getAllSkillsData = (value) => {
  //console.log('Inside getAllSkillsData start')

  return async (dispatch, store) => {
    let url = "";

    if (value) {
      url = `&search=${value}`;
    }

    await api(`lookup/get?type=S${url}`, "", "get")
      .then((res) => {
        if (res.status === 400) {
          toastr.error(res.data.error);
        } else {
          for (var i = 0; i < res.data.data.length; i++) {
            // res.data.data[i]['value'] = res.data.data[i].id;
            res.data.data[i]["label"] = res.data.data[i].value;
          }

          dispatch(setSkillsData(res.data.data));
        }
      })
      .catch((err) => {
        toastr.error("You are not able to login");
      });
    //console.log('Inside getAllSkillsData end')
  };
};

export const getAllTransportData = (value) => {
  //console.log('Inside getAllSkillsData start')

  return async (dispatch, store) => {
    let url = "";

    if (value) {
      url = `&search=${value}`;
    }

    await api(`lookup/get?type=T${url}`, "", "get")
      .then((res) => {
        if (res.status === 400) {
          toastr.error(res.data.error);
        } else {
          for (var i = 0; i < res.data.data.length; i++) {
            // res.data.data[i]['value'] = res.data.data[i].id;
            res.data.data[i]["label"] = res.data.data[i].value;
          }

          dispatch(setTransportsData(res.data.data));
        }
      })
      .catch((err) => {
        toastr.error("You are not able to login");
      });
  };
};

export const getUnAssignedSpeVouchers = (value) => {
  return async (dispatch, store) => {
    await api(`voucher/unAssignedSpeVouchers`, "", "post")
      .then((res) => {
        if (res.status === 400) {
          toastr.error(res.data.error);
        } else {
          let VoucherArray = res.data.data.VoucherList.map((item) => {
            return {
              label: item.code,
              value: item.id,
            };
          });

          dispatch(getAllUnAssignedVouchers(VoucherArray));
        }
      })
      .catch((err) => {
        toastr.error("You are not able to login");
      });
  };
};

export const getALLPartnerVouchers = (value) => {
  return async (dispatch, store) => {
    await api(`voucher/getPartnersVoucher`, "", "post")
      .then((res) => {
        if (res.status === 400) {
          toastr.error(res.data.error);
        } else {
          let VoucherArray = res.data.data.VoucherList.map((item) => {
            return {
              label: item.code,
              value: item.id,
            };
          });

          dispatch(setAllPartnerVouchers(VoucherArray));
        }
      })
      .catch((err) => {
        toastr.error("You are not able to login");
      });
  };
};

export const deleteSelfiOfUser = (value) => {
  return async (dispatch, store) => {
    await api("admin/V9/deleteSelfiOfUser", value, "put")
      .then((res) => {
        if (res.status == 400) {
          toastr.error(res.data.error);
        } else {
          //dispatch(getAllUserData());
          toastr.success("User Selfi deleted successfully!!");
        }
      })
      .catch((err) => {
        toastr.error("You are not able to login!");
      });
  };
};

export const deleteProfileImageOfUser = (value) => {
  return async (dispatch, store) => {
    await api("admin/V9/deleteProfileImageOfUser", value, "put")
      .then((res) => {
        if (res.status == 400) {
          toastr.error(res.data.error);
        } else {
          //dispatch(getAllUserData());
          toastr.success("User Profile Image deleted successfully!!");
        }
      })
      .catch((err) => {
        toastr.error("You are not able to login!");
      });
  };
};

export const updateTaskCompletionRateFlags = (value) => {
  return async (dispatch, store) => {
    await api("V10/updateTaskCompletionRateFlags", value, "put")
      .then((res) => {
        if (res.status == 400) {
          toastr.error(res.data.error);
        } else {
          //dispatch(getAllUserData());
          toastr.success(
            "Task Affected Change in Completion Rate updated succesfully!"
          );
        }
      })
      .catch((err) => {
        toastr.error("You are not able to login!");
      });
  };
};

export const rejectKycOfUser = (value) => {
  return async (dispatch, store) => {
    await api("admin/V9/rejectKycOfUser", value, "put")
      .then((res) => {
        if (res.status == 400) {
          toastr.error(res.data.error);
        } else {
          //dispatch(getAllUserData());
          toastr.success("User Kyc rejected successfully!!");
        }
      })
      .catch((err) => {
        toastr.error("You are not able to login!");
      });
  };
};

export const deleteDocOfUser = (value) => {
  return async (dispatch, store) => {
    await api("admin/V9/deleteDocOfUser", value, "put")
      .then((res) => {
        if (res.status == 400) {
          toastr.error(res.data.error);
        } else {
          //dispatch(getAllUserData());
          toastr.success("User doc deleted successfully!!");
        }
      })
      .catch((err) => {
        toastr.error("You are not able to login!");
      });
  };
};

export const getAllLanguageData = () => {
  //console.log('Inside getAllLanguageData start')

  return async (dispatch, store) => {
    await api(`lookup/get?type=L`, "", "get")
      .then((res) => {
        if (res.status === 400) {
          toastr.error(res.data.error);
        } else {
          for (var i = 0; i < res.data.data.length; i++) {
            // res.data.data[i]['value'] = res.data.data[i].id;
            res.data.data[i]["label"] = res.data.data[i].value;
          }

          dispatch(setLangulageData(res.data.data));
        }
      })
      .catch((err) => {
        toastr.error("You are not able to login");
      });
    //console.log('Inside getAllLanguageData end')
  };
};

export const editUser = (value) => {
  return async (dispatch, store) => {
    await api("editUserByAdmin", value, "put")
      .then((res) => {
        if (res.status == 400) {
          toastr.error(res.data.error);
        } else {
          //dispatch(getAllUserData());
          toastr.success("User edited successfully!!");
        }
      })
      .catch((err) => {
        toastr.error("You are not able to login!");
      });
  };
};

export const editTask = (value) => {
  return async (dispatch, store) => {
    await api("admin/task", value, "put")
      .then((res) => {
        if (res.status == 400) {
          toastr.error(res.data.error);
        } else {
          //dispatch(getAllUserData());
          toastr.success("Task edited successfully!!");
        }
      })
      .catch((err) => {
        toastr.error("You are not able to login!");
      });
  };
};

export const completeTaskByAdmin = (value) => {
  return async (dispatch, store) => {
    await api("task/V7/completeTaskByAdmin", value, "put")
      .then((res) => {
        if (res.status == 400) {
          toastr.error(res.data.error);
        } else {
          //dispatch(getAllUserData());
          toastr.success("Task complted successfully!!");
        }
      })
      .catch((err) => {
        toastr.error("You are not able to login!");
      });
  };
};

export const editCertificate = (value) => {
  return async (dispatch, store) => {
    await api("qualifiedSkill", value, "put")
      .then((res) => {
        if (res.status == 400) {
          toastr.error(res.data.error);
        } else {
          //dispatch(getAllUserData());
          if (value.status == "A") {
            toastr.success("Certificate Approved successfully!!");
          } else if (value.status == "R") {
            toastr.success("Certificate Rejected successfully!!");
          } else {
            toastr.success("Certificate Deleted successfully!!");
          }
        }
      })
      .catch((err) => {
        toastr.error("You are not able to login!");
      });
  };
};

export const editCertificateByAdmin = (value) => {
  return async (dispatch, store) => {
    await api("admin/qualifiedSkill", value, "put")
      .then((res) => {
        if (res.status == 400) {
          toastr.error(res.data.error);
        } else {
          //dispatch(getAllUserData());
          toastr.success("Certificate Edited successfully!!");
        }
      })
      .catch((err) => {
        toastr.error("You are not able to login!");
      });
  };
};

export const editPortfolio = (value) => {
  return async (dispatch, store) => {
    await api("portfolio", value, "put")
      .then((res) => {
        if (res.status == 400) {
          toastr.error(res.data.error);
        } else {
          //dispatch(getAllUserData());
          if (value.status == "A") {
            toastr.success("Portfolio Approved successfully!!");
          } else if (value.status == "R") {
            toastr.success("Portfolio Rejected successfully!!");
          } else {
            toastr.success("Portfolio Deleted successfully!!");
          }
        }
      })
      .catch((err) => {
        toastr.error("You are not able to login!");
      });
  };
};

export const markAsDone = (value) => {
  return async (dispatch, store) => {
    await api("V8/manualPayout", value, "put")
      .then((res) => {
        if (res.status == 400) {
          toastr.error(res.data.error);
        } else {
          toastr.success("Request mark As Done successfully!!");
        }
      })
      .catch((err) => {
        toastr.error("You are not able to login!");
      });
  };
};

export const editUserMangopay = (value) => {
  return async (dispatch, store) => {
    await api("editUserByAdminReflectMangopay", value, "put")
      .then((res) => {
        if (res.status == 400) {
          toastr.error(res.data.error);
        } else {
          //dispatch(getAllUserData());
          toastr.success("User edit successfully!!");
        }
      })
      .catch((err) => {
        toastr.error("You are not able to login!");
      });
  };
};

export const editCategory = (value) => {
  return async (dispatch, store) => {
    await api("lookup/V2/edit", value, "put")
      .then((res) => {
        if (res.status === 400) {
          toastr.error(res.data.error);
        } else {
          //dispatch(getAllUserData());
          toastr.success("Category edited successfully!!");
        }
      })
      .catch((err) => {
        toastr.error("You are not able to login!");
      });
  };
};

export const editReview = (value) => {
  return async (dispatch, store) => {
    await api("review/editRating", value, "put")
      .then((res) => {
        if (res.status === 400) {
          toastr.error(res.data.error);
        } else {
          //dispatch(getAllUserData());
          toastr.success("Review edited successfully!!");
        }
      })
      .catch((err) => {
        toastr.error("You are not able to login!");
      });
  };
};

export const editSkills = (value) => {
  return async (dispatch, store) => {
    await api("lookup/edit", value, "put")
      .then((res) => {
        if (res.status === 400) {
          toastr.error(res.data.error);
        } else {
          if (res.data.data.type == "T") {
            toastr.success("Transport edited successfully!!");
          }

          if (res.data.data.type == "S") {
            toastr.success("Skills edited successfully!!");
          }
        }
      })
      .catch((err) => {
        toastr.error("You are not able to login!");
      });
  };
};

export const editMessage = (value) => {
  return async (dispatch, store) => {
    await api("offer/message", value, "put")
      .then((res) => {
        if (res.status === 400) {
          toastr.error(res.data.error);
        } else {
          //dispatch(getAllUserData());
          toastr.success("Message edited successfully!!");
        }
      })
      .catch((err) => {
        toastr.error("You are not able to login!");
      });
  };
};

export const addUser = (value) => {
  return async (dispatch, store) => {
    await api("createUser", value, "post")
      .then((res) => {
        if (res.status !== 200) {
          toastr.error(res.data.error);
        } else {
          // dispatch(getAllCategoryData());
          toastr.success("User added successfully!");
        }
      })
      .catch((err) => {
        toastr.error("You are not able to login!");
      });
  };
};

export const addSkills = (value) => {
  return async (dispatch, store) => {
    await api("lookup/create", value, "post")
      .then((res) => {
        if (res.status === 400) {
          toastr.error(res.data.error);
        } else {
          // dispatch(getAllCategoryData());
          //    toastr.success('Skills added successfully!');
          if (res.data.data.type == "T") {
            toastr.success("Transport added successfully!!");
          }

          if (res.data.data.type == "S") {
            toastr.success("Skills added successfully!!");
          }
        }
      })
      .catch((err) => {
        toastr.error("You are not able to login!");
      });
  };
};

export const addCategory = (value) => {
  return async (dispatch, store) => {
    // dispatch(setloader(true));
    await api("lookup/V2/create", value, "post")
      .then((res) => {
        if (res.status === 400) {
          toastr.error(res.data.error);
        } else {
          // dispatch(getAllCategoryData());
          toastr.success("Category added successfully!");
        }
      })
      .catch((err) => {
        toastr.error("You are not able to login!");
      });
  };
};

export const deleteCategory = (value) => {
  return async (dispatch, store) => {
    await api("lookup/delete", value, "delete")
      .then((res) => {
        if (res.status === 400) {
          toastr.error(res.data.error);
        } else {
          // dispatch(getAllCategoryData());
          toastr.success("Category deleted successfully!");
        }
      })
      .catch((err) => {
        toastr.error("You are not able to login!");
      });
  };
};

export const deleteMessage = (value) => {
  return async (dispatch, store) => {
    await api("offer/message", value, "delete")
      .then((res) => {
        if (res.status === 400) {
          toastr.error(res.data.error);
        } else {
          // dispatch(getAllCategoryData());
          toastr.success("Message deleted successfully!");
        }
      })
      .catch((err) => {
        toastr.error("You are not able to login!");
      });
  };
};

export const activeInactiveUser = (value) => {
  return async (dispatch, store) => {
    await api("activeInactiveUser", value, "put")
      .then((res) => {
        if (res.status === 400) {
          toastr.error("The user is not approved yet!");
        } else if (res.data.data.is_active === true) {
          // dispatch(getAllCategoryData());
          toastr.success("User is activated successfully!");
        } else {
          toastr.success("User is In-activated successfully!");
        }
      })
      .catch((err) => {
        toastr.error("The user is not approved yet!");
      });
  };
};

export const activeInactiveCategory = (value) => {
  return async (dispatch, store) => {
    await api("lookup/activeInactive", value, "put")
      .then((res) => {
        if (res.status === 400) {
          toastr.error(res.data.error);
        } else if (res.data.data.is_active === true) {
          // dispatch(getAllCategoryData());
          toastr.success("Category is Active !");
        } else {
          toastr.success("Category is In-active!");
        }
      })
      .catch((err) => {
        toastr.error("You are not able to login!");
      });
  };
};

export const deleteSkills = (value) => {
  return async (dispatch, store) => {
    await api("lookup/delete", value, "delete")
      .then((res) => {
        if (res.status === 400) {
          toastr.error(res.data.error);
        } else {
          if (res.data.data.type == "T") {
            toastr.success("Transport deleted successfully!!");
          }

          if (res.data.data.type == "S") {
            toastr.success("Skills deleted successfully!!");
          }
        }
      })
      .catch((err) => {
        toastr.error("You are not able to login!");
      });
  };
};

export const approveUser = (value) => {
  return async (dispatch, store) => {
    await api("approveRejectUser", value, "put")
      .then((res) => {
        if (res.status === 400) {
          toastr.error(res.data.error);
        } else {
          toastr.success("User Approved!!");
        }
      })
      .catch((err) => {
        toastr.error("You are not able to login");
      });
  };
};

export const rejectUser = (value) => {
  return async (dispatch, store) => {
    await api("approveRejectUser", value, "put")
      .then((res) => {
        if (res.status === 400) {
          toastr.error(res.data.error);
        } else {
          toastr.error("User Rejected!!");
        }
      })
      .catch((err) => {
        toastr.error("You are not able to login");
      });
  };
};

export const logout = () => {
  return async (dispatch) => {
    localStorage.removeItem("cotasker");
    dispatch(setLoginFlag(false));
    dispatch(setLoginUser({}));
    dispatch(setUserPermissions([]));
    dispatch(setSubPermission({}));
    history.push("/login");
    // window.location.replace("/login");
    // toastr.success("You logout Successfully!!")
  };
};

export const getTaskDetailsById = (id) => {
  return async (dispatch, store) => {
    await api(`task/V2/viewTask?id=${id}`, "", "get")
      .then((res) => {
        if (res.status === 400) {
          toastr.error(res.data.error);
        } else {
          dispatch(setCurrentTaskDetails(res.data.data));
        }
        dispatch(setloader(false));
      })
      .catch((err) => {
        toastr.error("You are not able to login");
      });
  };
};

export const getUserDetailsById = (id) => {
  return async (dispatch, store) => {
    //    dispatch(setloader(true));
    await api(`viewUser?id=${id}`, "", "get")
      .then((res) => {
        if (res.status === 400) {
          toastr.error(res.data.error);
        } else {
          dispatch(setCurrentUserData(res.data.data));
        }

        //
        dispatch(setloader(false));
      })
      .catch((err) => {
        toastr.error("You are not able to login");
      });
  };
};

export const getSkillUserDetailsById = (id) => {
  return async (dispatch, store) => {
    //    dispatch(setloader(true));
    await api(`lookup/skillsWiseUserList?id=${id}`, "", "get")
      .then((res) => {
        if (res.status === 400 && res.status === 401) {
          toastr.error(res.data.error);
        } else {
          dispatch(setSkillsUserData(res.data.data));
        }

        //
        dispatch(setloader(false));
      })
      .catch((err) => {
        toastr.error("You are not able to login");
      });
  };
};

export const getTransportUserDetailsById = (id) => {
  return async (dispatch, store) => {
    //    dispatch(setloader(true));
    await api(`lookup/transportWiseUserList?id=${id}`, "", "get")
      .then((res) => {
        if (res.status === 400 && res.status === 401) {
          toastr.error(res.data.error);
        } else {
          dispatch(setTransportUserData(res.data.data));
        }

        dispatch(setloader(false));
      })
      .catch((err) => {
        toastr.error("You are not able to login");
      });
  };
};

export const getSkillUserDetailsByArray = (value) => {
  return async (dispatch, store) => {
    //    dispatch(setloader(true));
    await api(`lookup/mutliSkillsUserList`, value, "post")
      .then((res) => {
        if (res.status === 400 && res.status === 401) {
          toastr.error(res.data.error);
        } else {
          dispatch(setMutiSkillsUserData(res.data.data));
        }

        dispatch(setloader(false));
      })
      .catch((err) => {
        toastr.error("You are not able to login");
      });
  };
};

export const getTransportUserDetailsByArray = (value) => {
  return async (dispatch, store) => {
    //    dispatch(setloader(true));
    await api(`lookup/mutliTransportUserList`, value, "post")
      .then((res) => {
        if (res.status === 400 && res.status === 401) {
          toastr.error(res.data.error);
        } else {
          dispatch(setMutiTransportUserData(res.data.data));
        }

        dispatch(setloader(false));
      })
      .catch((err) => {
        toastr.error("You are not able to login");
      });
  };
};

export const deleteUser = (value) => {
  return async (dispatch, store) => {
    await api(`deleteUserByAdmin?user_id=${value.id}`, value, "delete")
      .then((res) => {
        if (res.status === 400) {
          toastr.error(res.data.error);
        } else {
          // dispatch(getAllCategoryData());
          toastr.success("User deleted successfully!");
        }
      })
      .catch((err) => {
        toastr.error("You are not able to login!");
      });
  };
};

export const deleteUserAccountApi = (value) => {
  return async (dispatch, store) => {
    await api(`deleteAccount?user_id=${value.user_id}`, value, "post")
      .then((res) => {
        if (res.status === 400) {
          toastr.error(res.data.error);
        } else {
          // dispatch(getAllCategoryData());
          toastr.success("User deleted successfully!");
        }
      })
      .catch((err) => {
        toastr.error("You are not able to login!");
      });
  };
};

export const cancelUserAccountDeleteRequest = (value) => {
  return async (dispatch, store) => {
    await api(
      `cancelDeleteAccountRequest?user_id=${value.user_id}`,
      value,
      "post"
    )
      .then((res) => {
        if (res.status === 400) {
          toastr.error(res.data.error);
        } else {
          // dispatch(getAllCategoryData());
          toastr.success("Request canceled successfully!");
        }
      })
      .catch((err) => {
        toastr.error("You are not able to login!");
      });
  };
};

export const cancelTask = (value) => {
  return async (dispatch, store) => {
    await api(`task/V3/cancelTaskByAdmin?id=${value.id}`, value, "delete")
      .then((res) => {
        if (res.status === 400) {
          toastr.error(res.data.error);
        } else {
          // dispatch(getAllCategoryData());
          toastr.success("Task Cancel successfully!");
        }
      })
      .catch((err) => {
        // toastr.error('You are not able to login!')
      });
  };
};

export const deleteAdminTask = (value) => {
  return async (dispatch, store) => {
    await api(`task/deleteTaskByAdmin?id=${value.id}`, value, "delete")
      .then((res) => {
        if (res.status === 400) {
          toastr.error(res.data.error);
        } else {
          // dispatch(getAllCategoryData());
          toastr.success("Task deleted successfully!");
        }
      })
      .catch((err) => {
        toastr.error("You are not able to login!");
      });
  };
};

export const getAllImageMarker = () => {
  return async (dispatch, store) => {
    //
    await api(`markerImageGet`, "", "get")
      .then(async (res) => {
        if (res.status === 400) {
          toastr.error(res.data.error);
        } else {
          await dispatch(setMarkerImages(res.data.data));
        }
        // await
      })
      .catch((err) => {
        toastr.error("You are not able to login");
      });
  };
};

export const addImageMarker = (value) => {
  return async (dispatch, store) => {
    await api("createImageMarker", value, "post")
      .then((res) => {
        if (res.status === 400) {
          toastr.error(res.data.error);
        } else {
          // dispatch(getAllCategoryData());
          toastr.success("Marker Image added successfully!");
        }
      })
      .catch((err) => {
        toastr.error("You are not able to login!");
      });
  };
};

export const deleteImageMarker = (value) => {
  return async (dispatch, store) => {
    await api("markerImage", value, "delete")
      .then((res) => {
        if (res.status === 400) {
          toastr.error(res.data.error);
        } else {
          // dispatch(getAllCategoryData());
          toastr.success("Marker Image deleted successfully!");
        }
      })
      .catch((err) => {
        toastr.error("You are not able to login!");
      });
  };
};

export const getAllSettings = () => {
  return async (dispatch, store) => {
    //
    await api(`setting/get`, "", "get")
      .then(async (res) => {
        if (res.status === 400) {
          toastr.error(res.data.error);
        } else {
          await dispatch(setAllSettings(res.data.data));
        }
        // await
      })
      .catch((err) => {
        toastr.error("You are not able to login");
      });
  };
};

export const addSettiongs = (value) => {
  return async (dispatch, store) => {
    await api("setting/create", value, "post")
      .then((res) => {
        if (res.status === 400) {
          toastr.error(res.data.error);
        } else {
          // dispatch(getAllCategoryData());
          toastr.success("Settings added successfully!");
        }
      })
      .catch((err) => {
        toastr.error("You are not able to login!");
      });
  };
};

export const editSettiongs = (value) => {
  return async (dispatch, store) => {
    await api("setting/update", value, "put")
      .then((res) => {
        if (res.status === 400) {
          toastr.error(res.data.error);
        } else {
          // dispatch(getAllCategoryData());
          toastr.success("Settings update successfully!");
        }
      })
      .catch((err) => {
        toastr.error("You are not able to login!");
      });
  };
};

export const getAllTransaction = () => {
  return async (dispatch, store) => {
    await api("transaction/getAllTransaction", {}, "GET")
      .then((res) => {
        if (res.status === 400) {
          toastr.error(res.data.error);
        } else {
          dispatch(setAllTransactionData(res.data.data));
          //    toastr.success('Settings update successfully!');
        }
      })
      .catch((err) => {
        toastr.error("You are not able to login!");
      });
  };
};

export const getAllTaskCommnet = (value) => {
  return async (dispatch, store) => {
    await api(`comment/get?task_id=${value}&from_Admin=${true}`, {}, "get")
      .then((res) => {
        if (res.status === 400) {
          toastr.error(res.data.error);
        } else {
          dispatch(setTaskCommnet(res.data.data));
          // dispatch(setloader(false))
        }
      })
      .catch((err) => {
        toastr.error("You are not able to login!");
      });
  };
};

export const sendPremotionalNotification = (value) => {
  return async (dispatch, store) => {
    // const url = "notification/sendNotification";
    const url = "notification/send-bulk-notification";
    await api(url, value, "post")
      .then((res) => {
        if (res.status === 400) {
          toastr.error(res.data.error);
        } else {
          toastr.success("Notification send successfully!");
        }
      })
      .catch((err) => {
        // toastr.error('You are not able to login!')
      });
  };
};

export const deletePromotionalNotification = (value) => {
  return async (dispatch, store) => {
    await api("notification/deletePromotional", value, "delete")
      .then((res) => {
        if (res.status === 400) {
          toastr.error(res.data.error);
        } else {
          // dispatch(getAllCategoryData());
          toastr.success("Notification deleted successfully!");
        }
      })
      .catch((err) => {
        toastr.error("You are not able to login!");
      });
  };
};

export const deleteReview = (value) => {
  return async (dispatch, store) => {
    await api("review/deleteRating", value, "delete")
      .then((res) => {
        if (res.status === 400) {
          toastr.error(res.data.error);
        } else {
          // dispatch(getAllCategoryData());
          toastr.success("Review deleted successfully!");
        }
      })
      .catch((err) => {
        toastr.error("You are not able to login!");
      });
  };
};

export const deleteComment = (value) => {
  return async (dispatch, store) => {
    //console.log({value})

    await api("comment/delete", value, "delete")
      .then((res) => {
        if (res.status === 400) {
          toastr.error(res.data.error);
        } else {
          // dispatch(getAllCategoryData());
          toastr.success("Comment deleted successfully!");
        }
      })
      .catch((err) => {
        toastr.error("You are not able to login!");
      });
  };
};

export const deleteReply = (value) => {
  return async (dispatch, store) => {
    //console.log({value})

    await api("reply/delete", value, "delete")
      .then((res) => {
        if (res.status === 400) {
          toastr.error(res.data.error);
        } else {
          // dispatch(getAllCategoryData());
          toastr.success("Reply deleted successfully!");
        }
      })
      .catch((err) => {
        toastr.error("You are not able to login!");
      });
  };
};

export const deleteOffer = (value) => {
  return async (dispatch, store) => {
    //console.log({value})

    await api("offer/deleteOfferByAdmin", value, "delete")
      .then((res) => {
        if (res.status === 400) {
          toastr.error(res.data.error);
        } else {
          // dispatch(getAllCategoryData());
          toastr.success("Offer deleted successfully!");
        }
      })
      .catch((err) => {
        toastr.error("You are not able to login!");
      });
  };
};

//delete Voucher
export const deleteVoucher = (value) => {
  return async (dispatch, store) => {
    await api("voucher", value, "delete")
      .then((res) => {
        if (res.status === 400) {
          toastr.error(res.data.error);
        } else {
          // dispatch(getAllCategoryData());
          toastr.success("Voucher deleted successfully!");
        }
        // dispatch(setloader(false));
      })
      .catch((err) => {
        toastr.error("You are not able to login!");
      });
  };
};

export const addVoucher = (value) => {
  return async (dispatch, store) => {
    await api("voucher", value, "post")
      .then((res) => {
        if (res.status !== 200) {
          toastr.error(res.data.error);
        } else {
          // dispatch(getAllCategoryData());
          toastr.success("Voucher added successfully!");
        }
      })
      .catch((err) => {
        toastr.error("You are not able to login!");
      });
  };
};

export const editVoucher = (value) => {
  return async (dispatch, store) => {
    await api("voucher", value, "put")
      .then((res) => {
        if (res.status === 400) {
          toastr.error(res.data.error);
        } else {
          //dispatch(getAllUserData());
          toastr.success("Voucher edited successfully!!");
        }
      })
      .catch((err) => {
        toastr.error("You are not able to login!");
      });
  };
};

export const assignVoucherToUsers = (value) => {
  return async (dispatch, store) => {
    await api("voucher/assignVouchersToUsers", value, "put")
      .then((res) => {
        if (res.status == 400) {
          toastr.error(res.data.error);
        } else {
          //dispatch(getAllUserData());
          toastr.success("Voucher applied successfully!!");
        }
      })
      .catch((err) => {
        toastr.error("You are not able to login!");
      });
  };
};

export const getVoucherDetailsById = (id) => {
  return async (dispatch, store) => {
    //    dispatch(setloader(true));
    await api(`voucher?id=${id}`, "", "get")
      .then((res) => {
        if (res.status === 400) {
          toastr.error(res.data.error);
        } else {
          dispatch(setCurrentVoucherData(res.data.data));
        }

        //
        dispatch(setloader(false));
      })
      .catch((err) => {
        toastr.error("You are not able to login");
      });
  };
};

export const getPortfolioDetailsById = (id) => {
  return async (dispatch, store) => {
    //    dispatch(setloader(true));
    await api(`portfolio?p_id=${id}`, "", "get")
      .then((res) => {
        // console.log({res})
        if (res.status === 400) {
          toastr.error(res.data.error);
        } else {
          dispatch(setCurrentPortfolioData(res.data.data));
        }

        //
        dispatch(setloader(false));
      })
      .catch((err) => {
        toastr.error("You are not able to login");
      });
  };
};

export const getCertificateDetailsById = (id) => {
  return async (dispatch, store) => {
    //    dispatch(setloader(true));
    await api(`qualifiedSkill?q_id=${id}`, "", "get")
      .then((res) => {
        if (res.status === 400) {
          toastr.error(res.data.error);
        } else {
          dispatch(setCurrentCertificateData(res.data.data));
        }

        //
        dispatch(setloader(false));
      })
      .catch((err) => {
        toastr.error("You are not able to login");
      });
  };
};

export const getTrackingDetailsById = (id) => {
  return async (dispatch, store) => {
    //    dispatch(setloader(true));
    await api(`onetrack?id=${id}`, "", "get")
      .then((res) => {
        if (res.status === 400) {
          toastr.error(res.data.error);
        } else {
          dispatch(setCurrentTrackingData(res.data.data));
        }

        //
        dispatch(setloader(false));
      })
      .catch((err) => {
        toastr.error("You are not able to login");
      });
  };
};

export const getOfferDetailsById = (id) => {
  return async (dispatch, store) => {
    //    dispatch(setloader(true));
    await api(`offer/view?id=${id}&from_Admin=${true}`, "", "get")
      .then((res) => {
        if (res.status === 400) {
          toastr.error(res.data.error);
        } else {
          dispatch(setCurrentOfferData(res.data.data));
        }

        //
        dispatch(setloader(false));
      })
      .catch((err) => {
        toastr.error("You are not able to login");
      });
  };
};

export const disAllowUser = (value) => {
  return async (dispatch, store) => {
    await api("voucher/disAllowVouchertoUsers", value, "put")
      .then((res) => {
        if (res.status == 400) {
          toastr.error(res.data.error);
        } else {
          //dispatch(getAllUserData());
          toastr.success("Dis Allow Voucher to User successfully!!");
        }
      })
      .catch((err) => {
        toastr.error("You are not able to login!");
      });
  };
};

export const editComment = (value) => {
  return async (dispatch, store) => {
    dispatch(setloader(true));

    await api("comment/edit", value, "put")
      .then((res) => {
        if (res.status === 400) {
          toastr.error(res.data.error);
        } else {
          //dispatch(getAllUserData());
          toastr.success("Comment edited successfully!!");
        }
      })
      .catch((err) => {
        toastr.error("You are not able to login!");
      });
  };
};

export const editReply = (value) => {
  return async (dispatch, store) => {
    dispatch(setloader(true));

    await api("reply/edit", value, "put")
      .then((res) => {
        if (res.status === 400) {
          toastr.error(res.data.error);
        } else {
          //dispatch(getAllUserData());
          toastr.success("Reply edited successfully!!");
        }
      })
      .catch((err) => {
        toastr.error("You are not able to login!");
      });
  };
};

export const addUserNote = (value) => {
  return async (dispatch, store) => {
    await api("usernote", value, "post")
      .then((res) => {
        if (res.status === 400) {
          toastr.error(res.data.error);
        } else {
          // dispatch(getAllCategoryData());
          toastr.success("User Note added successfully!");
        }
      })
      .catch((err) => {
        toastr.error("You are not able to login!");
      });
  };
};

export const deleteUserNote = (value) => {
  return async (dispatch, store) => {
    await api("usernote", value, "delete")
      .then((res) => {
        if (res.status === 400) {
          toastr.error(res.data.error);
        } else {
          // dispatch(getAllCategoryData());
          toastr.success("User Note deleted successfully!");
        }
      })
      .catch((err) => {
        toastr.error("You are not able to login!");
      });
  };
};

export const editUserNote = (value) => {
  return async (dispatch, store) => {
    await api("usernote", value, "put")
      .then((res) => {
        if (res.status === 400) {
          toastr.error(res.data.error);
        } else {
          // dispatch(getAllCategoryData());
          toastr.success("User Note edited successfully!");
        }
      })
      .catch((err) => {
        toastr.error("You are not able to login!");
      });
  };
};

export const blockUnBlockApi = (value) => {
  return async (dispatch, store) => {
    await api("blockUnBlockUser", value, "put")
      .then((res) => {
        if (res.status == 400) {
          toastr.error("SomeThing wrong!!");
        } else if (res.data.data.is_blocked == true && res.status == 200) {
          // dispatch(getAllCategoryData());
          toastr.success("User is blocked successfully!");
        } else if (res.data.data.is_blocked == false && res.status == 200) {
          toastr.success("User is Unblock successfully!");
        } else {
          toastr.error("SomeThing wrong!!");
        }
      })
      .catch((err) => {
        toastr.error("SomeThing wrong!!");
      });
  };
};
export const blockUnBlockDepositMoneyApi = (value) => {
  return async (dispatch, store) => {
    await api("blockUnblockDeposit", value, "post")
      .then((res) => {
        if (res.status == 400) {
          toastr.error("SomeThing wrong!!");
        } else if (value.is_deposit_blocked === true && res.status === 200) {
          // dispatch(getAllCategoryData());
          toastr.success("Deposit Money is blocked successfully!");
        } else if (value.is_deposit_blocked === false && res.status === 200) {
          toastr.success("Deposit Money  is Unblock successfully!");
        } else {
          toastr.error("SomeThing wrong!!");
        }
      })
      .catch((err) => {
        toastr.error("SomeThing wrong!!");
      });
  };
};

export const allowOneMoreOffer = (value) => {
  return async (dispatch, store) => {
    await api("admin/allowOneMoreOffer", value, "put")
      .then((res) => {
        if (res.status === 200) {
          toastr.success("Alloewed One More Offer successfully!");
        } else {
          // dispatch(getAllCategoryData());
          toastr.error(res.data.error);
        }
      })
      .catch((err) => {
        toastr.error("You are not able to login!");
      });
  };
};

export const markAsRead = (value) => {
  return async (dispatch, store) => {
    await api("admin/MarkAsSeen", value, "put")
      .then((res) => {
        if (res.status === 200) {
          toastr.success("Mark As Seen Succesfully!");
        } else {
          // dispatch(getAllCategoryData());
          toastr.error(res.data.error);
        }
      })
      .catch((err) => {
        toastr.error("You are not able to login!");
      });
  };
};
